body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.VkIdWebSdk__button_reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.VkIdWebSdk__button {
  background: #0077ff;
  cursor: pointer;
  transition: all .1s ease-out;
}

.VkIdWebSdk__button:hover{
  opacity: 0.8;
}

.VkIdWebSdk__button:active {
  opacity: .7;
  transform: scale(.97);
}

.VkIdWebSdk__button {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
}

.VkIdWebSdk__button_container {
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.VkIdWebSdk__button_icon + .VkIdWebSdk__button_text {
  margin-left: -28px;
}

.VkIdWebSdk__button_text {
  display: flex;
  font-family: -apple-system, system-ui, "Helvetica Neue", Roboto, sans-serif;
  flex: 1;
  justify-content: center;
  color: #ffffff;
}
